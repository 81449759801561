import axios from 'axios';
const fetchData = async (json: any) => {
  try {
    const response = await axios({
      method: 'post',
      url: 'https://from-func-trn-condx-001.azurewebsites.net/api/decrypt',
      headers: {
        'Content-Type': 'application/json',
      },
      data: json,
    });

    return response.data;
  } catch (err) {
    let message = 'Information not returned, please try again later!';
    if (axios.isAxiosError(err) && err.response && err.response.status === 401) {
      message = 'Unauthorized: Validation error, Incorrect url !';
    }
    return { error: message };
  }
};

export default fetchData;
